<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 17" fill="none">
    <path d="M12.1265 0.0847106C11.1119 0.133372 10.1363 0.576195 9.382 1.33045L6.42336 4.28909C6.88808 3.82194 8.61314 4.14311 9.03163 4.5616L10.8224 2.77084C11.219 2.37425 11.7153 2.13094 12.2238 2.10904C12.5693 2.09201 13.0584 2.165 13.4891 2.59566C13.8905 2.99712 13.9757 3.46427 13.9757 3.78301C13.9757 4.31586 13.7324 4.84627 13.3139 5.26233L10.1995 8.39615C9.41606 9.1796 8.22384 9.24286 7.53285 8.55187C7.13869 8.1577 6.48905 8.15527 6.09246 8.55187C5.69586 8.94846 5.69586 9.59566 6.09246 9.99225C6.80292 10.7027 7.73723 11.0628 8.70073 11.0628C9.74209 11.0628 10.8054 10.637 11.6204 9.81707L14.7543 6.70272C15.5499 5.90953 16 4.8487 16 3.78301C16 2.79274 15.6253 1.85113 14.9294 1.15527C14.1849 0.410745 13.1849 0.0360488 12.1265 0.0847106ZM7.29927 5.10661C6.25791 5.10661 5.17762 5.53483 4.3601 6.35235L1.24574 9.46671C0.450122 10.2599 0 11.3207 0 12.3864C0 13.3767 0.374696 14.3183 1.07056 15.0142C1.81509 15.7587 2.81508 16.1334 3.87348 16.0847C4.88808 16.0361 5.86375 15.5932 6.618 14.839L9.57664 11.8803C9.10949 12.3475 7.38686 12.0263 6.96837 11.6078L5.17762 13.3986C4.78102 13.7952 4.28467 14.0361 3.77616 14.0604C3.43066 14.0774 2.94161 14.0044 2.51095 13.5738C2.10949 13.1723 2.02433 12.7027 2.02433 12.3864C2.02433 11.8536 2.26764 11.3232 2.68613 10.9071L5.80049 7.77328C6.58394 6.98982 7.77616 6.92899 8.46715 7.61756C8.86375 8.01415 9.51338 8.01415 9.90754 7.61756C10.3041 7.22096 10.3041 6.57376 9.90754 6.17717C9.19708 5.46671 8.26034 5.10661 7.29927 5.10661Z"
    :fill="fill"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 16
    },
    height: {
      type: [Number, String],
      default: 17
    },
    fill: {
      type: String,
      default: '#9CA6BA'
    }
  }
}
</script>
